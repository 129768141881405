@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background: linear-gradient(135deg, #ffffff, #ffffff);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App {
  text-align: center;
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: rgb(0, 0, 0);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
}

.text {
  flex: 1;
  margin-right: 20px;
  margin-left: 40px;
  font-size: 3em;
  font-weight: bold;
  color: #ffffff;
  line-height: 1.4;
  font-family: 'Inter', sans-serif;
}

.image {
  flex: 1;
}

img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}




